$color-primary: #dbb360;
$color-primary-light: #ffdd97;
$color-primary-dark: #936b18;

$color-secondary: #834b26;
$color-secondary-dark: #1c1311;

$color-tertiary: #192e63;
$color-tertiary-light: #2d437b;
$color-tertiary-dark: #030f2d;

$color-black: #313639;
$color-offwhite: #faf6d9;
$color-white: #fff;

$font-family-title: 'Balthazar', serif;
$font-family-text: 'Open Sans', sans-serif;

$text-color-dark: #313639;
$text-color-light: #fff;