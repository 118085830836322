*,
*::after,
*::before {
  margin: 0;
  padding: 0;
}

html {
  // this defines 1 rem
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  box-sizing: border-box;
  color: $color-black;
  font-family: $font-family-text;
  font-size: 1.8rem;

  @include respond(tab-port) {
    font-size: 1.6rem;
  }
}

a {
  color: $color-primary;
  text-decoration: none;

  &:hover {
    color: $color-primary-light;
  }

  &:visited {
    color: $color-primary-dark;
  }
}

h1,
h2,
h3,
h4 {
  color: $color-primary-dark;
  font-family: $font-family-title;
  text-align: center;
  text-shadow: 2px 2px 8px rgba($color-black, 0.2);
}

h2 {
  font-size: 3rem;

  @include respond(tab-port) {
    font-size: 2.4rem;
  }

  @include respond(tiny-phone) {
    font-size: 2.2rem;
  }
}

h3 {
  font-size: 2.2rem;

  @include respond(tab-port) {
    font-size: 2rem;
  }
}
