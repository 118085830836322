.border-gradient {
  border: 8px solid;
  border-image-slice: 1;
  border-image-source: radial-gradient($color-primary-light, $color-primary-dark);
  border-width: 5px;

  &__only-bottom {
    border-left: 0;
    border-right: 0;
    border-top: 0;
  }
}

.btn {
  border: none;
  border-radius: 2px;
  font-family: $font-family-title;
  font-size: 2rem;
  height: 4rem;
  margin: 0 auto;
  width: 20rem;

  @include respond(phone) {
    font-size: 1.8rem;
    height: 3.6rem;
  }
}

.btn-submit {
  background-image: linear-gradient($color-tertiary-light, $color-tertiary);
  color: $color-primary-light;
  margin-bottom: 2rem;
  margin-top: 3rem;

  @include respond(phone) {
    margin-bottom: 1rem;
    margin-top: 1.8rem;
    width: 18rem;
  }

  @include respond(tiny-phone) {
    width: 16rem;
  }

  &:hover {
    background-image: linear-gradient($color-primary-light, $color-primary);
    color: $color-tertiary;
    outline: none;
  }

  &:active {
    outline: none;
  }
}

.contact-form {
  box-shadow: 0 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.1),
    0 0.6rem 2rem 0 rgba(0, 0, 0, 0.09);
  margin: 0 auto;
  margin-bottom: 8rem;
  margin-top: 8rem;
  padding: 3rem 9rem;
  width: 60%;

  @include respond(tab-port) {
    margin-bottom: 8rem;
    margin-top: 8rem;
    padding: 2rem 7rem;
    width: 80%;
  }

  @include respond(phone) {
    margin-bottom: 6rem;
    margin-top: 6rem;
    padding: 3rem;
    width: 90%;
  }

  @include respond(tiny-phone) {
    margin-bottom: 4rem;
    margin-top: 4rem;
    padding: 2rem;
  }
}

.footer {
  background-image: linear-gradient(
    $color-tertiary,
    darken($color-tertiary-light, 15%)
  );
  color: $color-primary;
  height: 20rem;
  position: relative;
  width: 100%;

  @include respond(tab-port) {
    height: 15rem;
  }

  @include respond(phone) {
    height: 12rem;
  }

  @include respond(tiny-phone) {
    height: 10rem;
  }

  &__attribution {
    bottom: 20%;
    font-family: $font-family-text;
    font-size: 1.6rem;
    left: 4rem;
    line-height: 1.3;
    position: absolute;

    @include respond(tab-port) {
      font-size: 1.4rem;
    }

    @include respond(phone) {
      bottom: 10%;
      left: 2rem;
    }
  }
}

.form-control {
  border: solid 1px lighten($color-black, 40%);
  font-size: 1.6rem;
  outline: none;
  resize: none;
}

.form-label {
  color: $color-tertiary-dark;
  font-family: $font-family-title;
  font-size: 2rem;

  @include respond(tiny-phone) {
    font-size: 1.6rem;
  }
}

.header {
  background-image: radial-gradient(
    $color-tertiary,
    lighten($color-tertiary-dark, 2%)
  );
}

.header__logo img {
  display: block;
  height: auto;
  margin: 0 auto;
  padding: 4rem 0 4rem 0;
  width: 80rem;

  @include respond(tab-port) {
    width: 60rem;
  }

  @include respond(phone) {
    padding: 4rem 0;
    width: 40rem;
  }

  @include respond(tiny-phone) {
    padding: 3rem 0;
    width: 35rem;
  }
}

.main-copy {
  margin: 0 auto;
  margin-top: 1.2rem;
  width: 75%;

  @include respond(phone) {
    width: 85%;
  }

  &--heading {
    margin: 3.2rem 0;

    @include respond(tab-port) {
      margin: 2.8rem 0;
    }

    @include respond(phone) {
      margin: 2.4rem 0;
    }
  }

  &--list {
    margin-left: 4rem;

    @include respond(phone) {
      margin-left: 2rem;
    }

    & li {
      width: 75vw;

      &:not(:last-child) {
        margin-bottom: 1.1rem;
      }
    }
  }

  &--text {
    margin: 3rem 0;

    @include respond(phone) {
      hyphens: auto;
    }

    &--bordered {
      border-bottom: 2px solid $color-primary;
      padding-bottom: 1rem;
      width: 50rem;

      @include respond(tab-port) {
        width: 40rem;
      }

      @include respond(phone) {
        border-bottom: none;
        width: 30rem;
      }
    }
  }
}

.menu {
  transform: translateY(-4rem);

  @include respond(phone) {
    transform: none;
  }

  @include respond(tiny-phone) {
    margin-bottom: 3.4rem;
  }

  &__menu__item {
    background-color: $color-white;
    border: 6px solid;
    border-image: radial-gradient($color-primary, $color-primary-dark) 1;
    box-shadow: 0 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.1),
      0 0.6rem 2rem 0 rgba(0, 0, 0, 0.09);
    height: 8rem;
    margin: 0 2rem;
    outline-offset: 1rem;
    position: relative;
    transition: all 0.3s ease-in-out;
    width: 30rem;

    @include respond(tab-port) {
      border: 4px solid;
      height: 6rem;
      margin: 0 1.4rem;
      width: 20rem;
    }

    @include respond(phone) {
      border: 2px solid;
      height: 4rem;
      margin-top: 1.6rem;
      width: 28rem;
    }

    &:hover {
      border: none;
      box-shadow: 0 2.5rem 4rem rgba($color-black, 0.5);
      outline: 1rem solid $color-primary-dark;
      transform: scale(1.05) translateY(-0.5rem);
    }

    &--text {
      color: $color-primary-dark;
      font-family: $font-family-title;
      font-size: 2rem;
      left: 50%;
      position: absolute;
      text-shadow: 2px 2px 8px rgba($color-black, 0.2);
      top: 50%;
      transform: translate(-50%, -50%);

      @include respond(tab-port) {
        font-size: 1.6rem;
        text-align: center;
      }
    }
  }

  &:hover &__menu__item:not(:hover) {
    transform: scale(0.95);
  }
}

.wrapper {
  background-color: $color-white;
  margin: 0;
  padding: 0;
}
