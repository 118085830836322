@mixin respond($breakpoint) {
  @if $breakpoint == tiny-phone {
    @media (max-width: 25em) {
      // 400px
      @content;
    }
  }
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      // 600px
      @content;
    }
  }
  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {
      // 900px
      @content;
    }
  }
  @if $breakpoint == tab-land {
    @media (max-width: 75em) {
      // 1200px
      @content;
    }
  }
}
